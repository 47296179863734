<template>
  <div>
    <v-app-bar
      app
      flat
      height="70"
      :style="styles.app"
    >
      <v-img
        max-height="100"
        max-width="200"
        src="@/assets/images/logo_lg.png"
        style="cursor: pointer;"
        @click="$router.push({ name: 'MyPage' }, () => {})"
      />

      <v-spacer></v-spacer>

      <span
        class="lead-copy text-h6 primary--text text--darken-2 font-weight-bold"
        :style="$vuetify.breakpoint.xsOnly ?  'display: none;' : ''"
      >
        スキルアップと事業部を超えたつながりをクエストで！
      </span>

      <v-spacer></v-spacer>

      <!-- ヘルプページ -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon>
            <!-- 新着マークは新着お知らせがある場合のみ表示 -->
              <v-icon
                color="blue-grey lighten-1"
                v-bind="attrs"
                v-on="on"
                @click="goTo({ name: 'Help' })"
              >
                mdi-help
              </v-icon>
          </v-btn>
        </template>
        <span>クエストについて</span>
      </v-tooltip>

      <!-- お知らせ関連 -->
      <v-menu left offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon style="position: relative;">
            <div>
              <!-- 未読のお知らせがある場合はその件数を表示 -->
              <span
                v-if="latestNotifications.length"
                class="badge"
                v-bind="attrs"
                v-on="on"
              >
                {{ unreadCount }}
              </span>

              <v-icon
                size="28"
                color="blue-grey lighten-1"
                v-bind="attrs"
                v-on="on"
              >
                mdi-bell
              </v-icon>
            </div>
          </v-btn>
        </template>
        <v-card width="350">
          <v-list dense>
            <v-card flat class="d-flex justify-center mt-n2">
              <v-subheader class="font-weight-bold">
                <v-icon small class="me-1">mdi-bell</v-icon>新着お知らせ
              </v-subheader>
            </v-card>
            <v-divider />

            <!-- 新着お知らせ -->
            <template v-if="latestNotifications.length > 0">
              <template v-for="(item, index) in latestNotifications">
                <v-list-item
                  color="primary"
                  three-line
                  :key="index"
                  @click="goToByNotification(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item.notification.title" />
                    <v-list-item-subtitle v-text="item.notification.content" />
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text v-text="calcElapsedTime(item.notification.createdAt)" />
                  </v-list-item-action>
                </v-list-item>
                <v-divider :key="index+'-divider'" />
              </template>
            </template>

            <!-- 新着お知らせなし -->
            <template v-else>
              <v-list-item>
                <v-list-item-content class="text-center">
                  <v-list-item-title v-text="'お知らせはありません'" />
                </v-list-item-content>
              </v-list-item>
              <v-divider />
            </template>

            <!-- お知らせ一覧リンク -->
            <v-list-item
              class="text-center"
              @click="$router.push({ name: 'Notification' }, () => {})"
            >
              <v-list-item-content>
                <v-list-item-title class="text-decoration-underline" style="color: #fa9f00;" v-text="'全てのお知らせを見る'" />
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

      <!-- アカウントメニュー関連 -->
      <v-menu offset-y single-line>
        <template v-slot:activator="{ on }">
          <v-btn icon x-large v-on="on">
            <v-avatar :color="getProfileImg ? 'white' : 'blue-grey lighten-1'" size="32" >
              <img v-if="getProfileImg" :src="getProfileImg" />
              <v-icon v-else color="white" size="28" medium>mdi-account</v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <v-card
          class="mx-auto"
          max-width="400"
        >
          <v-list-item
            class="ms-n4"
            style="height: 50px;"
            @click="$router.push({ name: 'ProfileEdit' }, () => {})"
          >
            <v-list-item-avatar>
              <v-icon small>mdi-account-edit</v-icon>
            </v-list-item-avatar>
            <v-list-item-content class="ms-n5">
              <v-list-item-title style="font-size: 13px;" v-text="'プロフィール編集'" />
            </v-list-item-content>
          </v-list-item>
          <v-divider />

          <v-list-item
            class="ms-n4"
            style="height: 50px;"
            @click="onShowPublicProfile"
          >
            <v-list-item-avatar>
              <v-icon small>mdi-earth</v-icon>
            </v-list-item-avatar>
            <v-list-item-content class="ms-n5">
              <v-list-item-title style="font-size: 13px;" v-text="'公開プロフィールを確認'" />
            </v-list-item-content>
          </v-list-item>
          <v-divider />

          <v-list-item
            class="ms-n4"
            style="height: 50px;"
            @click="signOut()"
          >
            <v-list-item-avatar>
              <v-icon small>mdi-logout</v-icon>
            </v-list-item-avatar>
            <v-list-item-content class="ms-n5">
              <v-list-item-title
                class="red--text text--lighten-1"
                style="font-size: 13px;"
                v-text="'ログアウト'"
              />
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-menu>

      <div class="hidden-xs-only ms-3">
        <div class="caption">
          {{ me.department.name }}
        </div>
        <div>
          {{ me.nickname }}
        </div>
      </div>

      <!-- ヘッダー -->
      <template v-slot:extension>
        <v-tabs
          class="mt-2"
          centered
          fixed-tabs
          icons-and-text
          show-arrows
          hide-slider
          :style="headerStyle.gradient"
        >
          <v-tab :to="{ name: 'SearchQuest' }">
            クエストを探す<v-icon color="white">mdi-shield-search</v-icon>
          </v-tab>

          <v-tab :to="{ name: 'OrderQuest' }">
            クエストを<br v-if="$vuetify.breakpoint.xsOnly" />依頼する<v-icon color="white">mdi-shield-plus</v-icon>
          </v-tab>

          <v-tab
            v-if="me.company.isQuestApproval && me.isApprover"
            :to="{ name: 'SearchWaitingQuest' }"
          >
            承認待ち<br v-if="$vuetify.breakpoint.xsOnly" />クエスト<v-icon color="white">mdi-shield-check</v-icon>
          </v-tab>

          <v-tab :to="{ name: 'MyPage' }">
            マイページ<v-icon color="white">mdi-card-account-details</v-icon>
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <!-- 公開プロフィールモーダル -->
    <public-profile-modal
      :show="showPublicProfile"
      :user-info="me"
      @cancel="onShowPublicProfile"
    />
  </div>
</template>

<script>
import AppMethodsMixin from '@/mixins/AppMethodsMixin';
import AppStyleMixin from '@/mixins/AppStyleMixin';
import PublicProfileModal from '@/components/organisms/PublicProfileModal';
import { ROLES } from '@/assets/constants';

export default {
  components: { PublicProfileModal },
  mixins: [AppMethodsMixin, AppStyleMixin],
  data() {
    return {
      userRole: ROLES,
      showPublicProfile: false
    };
  },
  computed: {
    me() {
      const me = this.$store.getters['users/getMe'];
      return me.token
        ? me
        : {
            fullname: '---',
            department: { name: '---' },
            company: { isQuestApproval: false }
          };
    },
    latestNotifications() {
      return this.$store.getters['userNotifications/getLatest'];
    },
    headerStyle() {
      return {
        gradient: {
          background: `linear-gradient(90deg, ${this.$vuetify.theme.themes.light.primary}, ${this.$vuetify.theme.themes.light.secondary}) !important`,
          height: '72px'
        }
      };
    },
    // プロフィール画像取得
    getProfileImg() {
      return this.$store.getters['getProfileImg'];
    },
    unreadCount() {
      return this.$store.getters['userNotifications/getUnreadCount'];
    }
  },
  methods: {
    /** 画面遷移 */
    async goToByNotification(item) {
      // お知らせを既読にする
      await this.$store.dispatch('userNotifications/read', item.id);

      // 画面遷移
      const routerParams = JSON.parse(item.notification.link);
      this.goTo(routerParams);
    },
    // 指定のページに遷移
    goTo(routerParams) {
      if (
        this.$route.name === routerParams.name &&
        JSON.stringify(this.sortObject(this.$route.params)) === JSON.stringify(this.sortObject(routerParams.params))
      ) {
        this.$router.go({ ...routerParams, ...{ forse: true } }, () => {});
      } else {
        this.$router.push(routerParams, () => {});
      }
    },
    /** ログアウト */
    signOut() {
      this.$store.dispatch('authentications/signOut');
    },
    /** 発注者プロフィールをモーダル表示 */
    onShowPublicProfile() {
      this.showPublicProfile = !this.showPublicProfile;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-tab {
  color: rgba(0, 0, 0, 0.38) !important;
  .v-icon {
    color: rgba(0, 0, 0, 0.38) !important;
    position: relative;
  }
  &:hover {
    color: white !important;
    .v-icon {
      color: white !important;
    }
  }
}

.v-tab--active {
  font-weight: bold;
  color: white !important;
  .v-icon {
    color: white !important;
    position: relative;
  }
}

// 画面の横幅リサイズで狭くなった場合、リードコピーの溢れた部分を「...」に変える
.lead-copy {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// 新着お知らせがあった場合にベルマークの右上に件数を表示する
.badge {
  position: absolute;
  top: -13px;
  left: 50%;
  font-size: .05rem;
  font-weight: bold;
  line-height: 23px;
  letter-spacing: -0.1em;
  height: 24px;
  width: 24px;
  background: #FA9F00;
  border-radius: 50%;
  border: 1px solid;
  border-color: #fff;
  color: #FFFFFF;
  text-align: center;
  z-index: 1;
}
</style>
