import common from '@/store/common';
import dayjs from 'dayjs';
import {
  QUEST_STATUS,
  RECEIVE_QUEST_STATUS,
  RECEIVE_QUEST_STEP
} from '@/assets/constants';

export default {
  methods: {
    // 数字を3桁区切り文字列にして返す
    intcomma(num) {
      return common.intcomma(num);
    },

    // 指定した日付までの日数を返す
    calcDaysUntil(targetDay) {
      targetDay = dayjs(targetDay);
      if (!targetDay.isValid()) {
        return '---';
      }
      // 指定日付までの日数
      const days = targetDay.diff(dayjs(), 'day');

      // 指定日付までの日数が365日以上の場合は日数を365で割った商と余りを返す
      const year = Math.floor(days / 365);
      if (year > 0) {
        return `${year}年${days % 365}`;
      }

      // 指定日付までの日数が365日未満の場合は日数をそのまま返す
      return days;
    },

    /**
     * 数値入力以外を弾く
     * 
     * [ usage ]
     * @keypress="isNumberInput($event)"
     */
    onNumberInput(evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
        return false;
      } else {
        return true;
      }
    },

    /**
     * 経過時間を文字列で返す
     * @param {*} datetime
     * @returns
     */
    calcElapsedTime(datetime) {
      const from = new Date(datetime);

      // 経過時間取得
      const diff = new Date().getTime() - from.getTime();

      // 経過時間をDateに変換
      var elapsed = new Date(diff);

      // 大きい単位から順に表示
      if (elapsed.getUTCFullYear() - 1970) {
        return elapsed.getUTCFullYear() - 1970 + '年前';
      } else if (elapsed.getUTCMonth()) {
        return elapsed.getUTCMonth() + 'ヶ月前';
      } else if (elapsed.getUTCDate() - 1) {
        return elapsed.getUTCDate() - 1 + '日前';
      } else if (elapsed.getUTCHours()) {
        return elapsed.getUTCHours() + '時間前';
      } else if (elapsed.getUTCMinutes()) {
        return elapsed.getUTCMinutes() + '分前';
      } else {
        return 'たった今';
      }
    },

    /**
     * 日付のフォーマット
     * @param {*} date
     * @param {*} format
     * @returns
     */
    dateFormat(date, format='YYYY年MM月DD日') {
      date = dayjs(date);
      if (!date.isValid()) {
        return;
      }
      return date.format(format);
    },

    /**
     * 全画面ローディング表示
     * @param {*} func
     */
    async showLoadingOverlay(func) {
      this.$store.commit('showLoadingOverlay');
      try {
        await func();
      } finally {
        this.$store.commit('hideLoadingOverlay');
      }
    },

    /** テキスト内の改行コードをbrタグに変換する */
    nl2br(content) {
      if (!content) {
        return '';
      }
      return content
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;')
        .replace(/(?:\r\n|\r|\n)/g, '<br>');
    },

    /** クエストステータスのラベルを返す */
    getQuestStatusLabel(status) {
      let label = '';
      Object.keys(QUEST_STATUS).forEach(key => {
        if (QUEST_STATUS[key].value == status) {
          label = QUEST_STATUS[key].label;
        }
      });
      return label;
    },

    /** 受注クエストステータスのラベルを返す */
    getReceiveQuestStatusLabel(status) {
      let label = '';
      Object.keys(RECEIVE_QUEST_STATUS).forEach(key => {
        if (RECEIVE_QUEST_STATUS[key].value === status) {
          label = RECEIVE_QUEST_STATUS[key].label;
        }
      });
      return label;
    },

    getReceiveQuestStepLabel(status) {
      let label = '';
      Object.keys(RECEIVE_QUEST_STEP).forEach(key => {
        if (RECEIVE_QUEST_STEP[key].value === status) {
          label = RECEIVE_QUEST_STEP[key].label;
        }
      });
      return label;
    },

    /** 期限切れラベルを返す */
    expiredLabel(deadline) {
      if (dayjs(new Date()).isAfter(dayjs(deadline))) {
        return '募集期限切れ';
      }
      return null;
    },

    /** プロフィール画像を返す */
    getProfileImgByToken(token) {
      if (!token) {
        return null;
      }
      return this.$store.state.profileImages[token];
    },

    /** オブジェクトをソート */
    sortObject(obj) {
      const sorted = Object.entries(obj).sort();

      // valueを調べ、objectならsorted entriesに変換する
      for (let i in sorted){
          const val = sorted[i][1];
          if (typeof val === 'object') {
              sorted[i][1] = this.sortObject(val);
          }
      }
      return sorted;
    }
  }
};
