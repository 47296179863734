import request from '@/api/common';

const ENDPOINT = 'v1/receive_quest';

/*
  eslint-disable
  no-unused-vars
*/
export default {
  /**
   * カレントユーザーの受注クエストを一覧取得
   * @param {*} context
   * @param {*} queryString
   * @returns
   */
  async list({ dispatch, commit, rootState, rootGetters }, queryString) {
    let endPoint = `${ENDPOINT}`;
    if (queryString) {
      endPoint += queryString;
    }
    const response = await request.get(endPoint);
    
    // クエスト発注者のプロフィール画像をstateに保存
    response.data.results.forEach(res => {
      commit('addProfileImg', { companyCode: res.quest.client.company.code, token: res.quest.client.token }, { root: true });
    });

    return response.data;
  },

  /**
   * クエスト進行状況取得
   * @param {*} context
   * @param {*} contractId
   * @returns
   */
  async retrieve({ dispatch, commit, rootState, rootGetters }, contractId) {
    const response = await request.get(`${ENDPOINT}/${contractId}`);
    return response.data;
  },

  /**
   * 進行状況更新
   * @param {*} context
   * @param {*} payload
   */
  async complete({ dispatch, commit, rootState, rootGetters }, payload) {
    const response = await request.put(`${ENDPOINT}/${payload.contractId}/progress/update?step=${payload.step}`);
    return response.data;
  },

  /**
   * 受注者評価登録（発注者 >> 受注者の評価）
   * @param {*} context
   * @param {*} payload
   */
   async sendChallengerRating({ dispatch, commit, rootState, rootGetters }, payload) {
    const response = await request.post(`${ENDPOINT}/${payload.contractId}/challenger_rating`, payload.params);
    return response.data;
  },

  /**
   * 発注者評価登録（受注者 >> 発注者の評価）
   * @param {*} context
   * @param {*} payload
   */
  async sendClientRating({ dispatch, commit, rootState, rootGetters }, payload) {
    const response = await request.post(`${ENDPOINT}/${payload.contractId}/client_rating`, payload.params);
    return response.data;
  },

  /**
   * 受注者評価取得
   * @param {*} context
   * @param {*} payload
   */
  async getChallengerRating({ dispatch, commit, rootState, rootGetters }, payload) {
    const response = await request.get(`${ENDPOINT}/${payload.contractId}/challenger_rating?challenger_token=${payload.challengerId}`);
    return response.data;
  },

  /**
   * 発注者評価取得
   * @param {*} context
   * @param {*} payload
   */
   async getClientRating({ dispatch, commit, rootState, rootGetters }, payload) {
    const response = await request.get(`${ENDPOINT}/${payload.contractId}/client_rating?client_token=${payload.clientId}`);
    return response.data;
  },

  /**
   * メッセージ送信
   * @param {*} context
   * @param {*} payload
   */
  async sendMessage({ dispatch, commit, rootState, rootGetters }, payload) {
    const response = await request.post(`${ENDPOINT}/${payload.contractId}/send_message`, payload.data);
    return response.data;
  },

  /**
   * ファイル送信
   * @param {*} context
   * @param {*} payload
  */
  async sendAttachment({ dispatch, commit, rootState, rootGetters }, payload) {
    const response = await request.post(`${ENDPOINT}/${payload.contractId}/send_attachment`, payload.data, true, true);
    return response.data;
  },

  /**
   * ファイルダウンロード
   * @param {*} context
   * @param {*} payload
  */
   async downloadAttachment({ dispatch, commit, rootState, rootGetters }, payload) {
    return await request.download(`${ENDPOINT}/${payload.contractId}/download_attachment?download_path=${payload.downloadPath}`);
  }
};
/*
  eslint-enable
  no-unused-vars
*/
