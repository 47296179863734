import { getDefaultState } from '@/store/modules/quests';

export default {
  initializeState(state) {
    Object.assign(state, getDefaultState());
  },

  /**
   * 初期値を設定
   * @param {*} state
   * @param {*} key
   */
  setDefault(state, key) {
    state[key] = getDefaultState()[key];
  },

  /**
   * 登録するクエストのデータを保存
   * @param {*} state
   * @param {*} payload
   */
  setCurrentQuest(state, payload) {
    localStorage.setItem('currentQuest', JSON.stringify(payload));
    state.currentQuest = payload;
  }
};
