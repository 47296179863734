var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{style:(_vm.styles.app),attrs:{"app":"","flat":"","height":"70"},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-tabs',{staticClass:"mt-2",style:(_vm.headerStyle.gradient),attrs:{"centered":"","fixed-tabs":"","icons-and-text":"","show-arrows":"","hide-slider":""}},[_c('v-tab',{attrs:{"to":{ name: 'SearchQuest' }}},[_vm._v(" クエストを探す"),_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-shield-search")])],1),_c('v-tab',{attrs:{"to":{ name: 'OrderQuest' }}},[_vm._v(" クエストを"),(_vm.$vuetify.breakpoint.xsOnly)?_c('br'):_vm._e(),_vm._v("依頼する"),_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-shield-plus")])],1),(_vm.me.company.isQuestApproval && _vm.me.isApprover)?_c('v-tab',{attrs:{"to":{ name: 'SearchWaitingQuest' }}},[_vm._v(" 承認待ち"),(_vm.$vuetify.breakpoint.xsOnly)?_c('br'):_vm._e(),_vm._v("クエスト"),_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-shield-check")])],1):_vm._e(),_c('v-tab',{attrs:{"to":{ name: 'MyPage' }}},[_vm._v(" マイページ"),_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-card-account-details")])],1)],1)]},proxy:true}])},[_c('v-img',{staticStyle:{"cursor":"pointer"},attrs:{"max-height":"100","max-width":"200","src":require("@/assets/images/logo_lg.png")},on:{"click":function($event){_vm.$router.push({ name: 'MyPage' }, function () {})}}}),_c('v-spacer'),_c('span',{staticClass:"lead-copy text-h6 primary--text text--darken-2 font-weight-bold",style:(_vm.$vuetify.breakpoint.xsOnly ?  'display: none;' : '')},[_vm._v(" スキルアップと事業部を超えたつながりをクエストで！ ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"blue-grey lighten-1"},on:{"click":function($event){return _vm.goTo({ name: 'Help' })}}},'v-icon',attrs,false),on),[_vm._v(" mdi-help ")])],1)]}}])},[_c('span',[_vm._v("クエストについて")])]),_c('v-menu',{attrs:{"left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticStyle:{"position":"relative"},attrs:{"icon":""}},[_c('div',[(_vm.latestNotifications.length)?_c('span',_vm._g(_vm._b({staticClass:"badge"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.unreadCount)+" ")]):_vm._e(),_c('v-icon',_vm._g(_vm._b({attrs:{"size":"28","color":"blue-grey lighten-1"}},'v-icon',attrs,false),on),[_vm._v(" mdi-bell ")])],1)])]}}])},[_c('v-card',{attrs:{"width":"350"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-card',{staticClass:"d-flex justify-center mt-n2",attrs:{"flat":""}},[_c('v-subheader',{staticClass:"font-weight-bold"},[_c('v-icon',{staticClass:"me-1",attrs:{"small":""}},[_vm._v("mdi-bell")]),_vm._v("新着お知らせ ")],1)],1),_c('v-divider'),(_vm.latestNotifications.length > 0)?[_vm._l((_vm.latestNotifications),function(item,index){return [_c('v-list-item',{key:index,attrs:{"color":"primary","three-line":""},on:{"click":function($event){return _vm.goToByNotification(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.notification.title)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.notification.content)}})],1),_c('v-list-item-action',[_c('v-list-item-action-text',{domProps:{"textContent":_vm._s(_vm.calcElapsedTime(item.notification.createdAt))}})],1)],1),_c('v-divider',{key:index+'-divider'})]})]:[_c('v-list-item',[_c('v-list-item-content',{staticClass:"text-center"},[_c('v-list-item-title',{domProps:{"textContent":_vm._s('お知らせはありません')}})],1)],1),_c('v-divider')],_c('v-list-item',{staticClass:"text-center",on:{"click":function($event){_vm.$router.push({ name: 'Notification' }, function () {})}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-decoration-underline",staticStyle:{"color":"#fa9f00"},domProps:{"textContent":_vm._s('全てのお知らせを見る')}})],1)],1)],2)],1)],1),_c('v-menu',{attrs:{"offset-y":"","single-line":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-large":""}},on),[_c('v-avatar',{attrs:{"color":_vm.getProfileImg ? 'white' : 'blue-grey lighten-1',"size":"32"}},[(_vm.getProfileImg)?_c('img',{attrs:{"src":_vm.getProfileImg}}):_c('v-icon',{attrs:{"color":"white","size":"28","medium":""}},[_vm._v("mdi-account")])],1)],1)]}}])},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"400"}},[_c('v-list-item',{staticClass:"ms-n4",staticStyle:{"height":"50px"},on:{"click":function($event){_vm.$router.push({ name: 'ProfileEdit' }, function () {})}}},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-edit")])],1),_c('v-list-item-content',{staticClass:"ms-n5"},[_c('v-list-item-title',{staticStyle:{"font-size":"13px"},domProps:{"textContent":_vm._s('プロフィール編集')}})],1)],1),_c('v-divider'),_c('v-list-item',{staticClass:"ms-n4",staticStyle:{"height":"50px"},on:{"click":_vm.onShowPublicProfile}},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-earth")])],1),_c('v-list-item-content',{staticClass:"ms-n5"},[_c('v-list-item-title',{staticStyle:{"font-size":"13px"},domProps:{"textContent":_vm._s('公開プロフィールを確認')}})],1)],1),_c('v-divider'),_c('v-list-item',{staticClass:"ms-n4",staticStyle:{"height":"50px"},on:{"click":function($event){return _vm.signOut()}}},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-logout")])],1),_c('v-list-item-content',{staticClass:"ms-n5"},[_c('v-list-item-title',{staticClass:"red--text text--lighten-1",staticStyle:{"font-size":"13px"},domProps:{"textContent":_vm._s('ログアウト')}})],1)],1)],1)],1),_c('div',{staticClass:"hidden-xs-only ms-3"},[_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.me.department.name)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.me.nickname)+" ")])])],1),_c('public-profile-modal',{attrs:{"show":_vm.showPublicProfile,"user-info":_vm.me},on:{"cancel":_vm.onShowPublicProfile}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }