<template>
  <v-app id="inspire">
    <!-- ローディング -->
    <app-loading-overlay />

    <!-- ヘッダー -->
    <app-header v-if="$route.meta.showHeader" />

    <v-main :style="styles.app">
      <router-view />
    </v-main>

    <!-- フッター -->
    <app-footer />

    <!-- TOPへ戻るボタン -->
    <app-scroll-top-btn />

    <notification />
  </v-app>
</template>

<script>
import AppFooter from '@/components/globals/AppFooter';
import AppHeader from '@/components/globals/AppHeader';
import AppLoadingOverlay from '@/components/globals/AppLoadingOverlay';
import AppScrollTopBtn from '@/components/globals/AppScrollTopBtn';
import AppStyleMixin from '@/mixins/AppStyleMixin';
import Notification from '@/components/globals/Notification';

export default {
  components: {
    AppFooter,
    AppHeader,
    AppLoadingOverlay,
    AppScrollTopBtn,
    Notification
  },
  mixins: [AppStyleMixin],
  created() {
    this.isMaintenance();
  },
  watch: {
    $route() {
      this.isMaintenance();
    }
  },

  methods: {
    // 新着お知らせ取得
    getLatestNotifications() {
      this.$store.dispatch('notifications/latest');
    },
    // メンテナンスか否か
    isMaintenance() {
      if(process.env.VUE_APP_MAINTENANCE.toLowerCase() == 'true'){
        this.$router.push({ path: '/maintenance' });
      }
    }
  },
  mounted() {
    if (window.location.pathname.match('login')) {
      return;
    }
    this.$store.dispatch('users/me');
  }
};
</script>
