/*
  eslint-disable
  no-unused-vars
*/
export default {
  getAuthentication: (state, getters, rootState, rootGetters) => {
    return state.authentication;
  },

  getAccessToken: (state, getters, rootState, rootGetters) => {
    if (!state.accessToken) {
      return localStorage.getItem('accessToken');
    }
    return state.accessToken;
  },

  getLoading: (state, getters, rootState, rootGetters) => {
    return state.loading;
  },

  getProfileImg: (state, getters, rootState, rootGetters) => {
    const me = getters['users/getMe'];
    if (!me || !me.token) {
      return null;
    }
    return state.profileImages[me.token];
  }
};
/*
  eslint-enable
  no-unused-vars
*/
