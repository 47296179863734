/*
  eslint-disable
  no-unused-vars
*/
export default {
  getLatest: (state, getters, rootState, rootGetters) => {
    return state.latest;
  },

  getUnreadCount: (state, getters, rootState, rootGetters) => {
    return state.unreadCount;
  }
};
/*
  eslint-enable
  no-unused-vars
*/
