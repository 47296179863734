import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

let auth = null;
let firestore =  null;
switch (process.env.NODE_ENV) {
  // 開発環境
  case 'development': {
    console.log('FirebaseAuth: debug mode');
    const config = require('../../devServiceAccount.json');
    const firebaseAuthApp = firebase.initializeApp(config, 'firebaseAuth');
    auth = firebase.auth(firebaseAuthApp);
    firestore =  firebase.firestore(firebaseAuthApp);

    // 開発時はエミュレータに接続
    auth.useEmulator('http://localhost:9099');
    firestore.useEmulator('localhost', '8111');
    break;
  }

  // ステージング環境
  case 'staging': {
    const config = require('../../stgAuthServiceAccount.json');
    const firebaseAuthApp = firebase.initializeApp(config, 'firebaseAuth');
    auth = firebase.auth(firebaseAuthApp);
    firestore =  firebase.firestore(firebaseAuthApp);
    break;
  }

  // 本番環境
  case 'production': {
    const config = require('../../prdAuthServiceAccount.json');
    const firebaseAuthApp = firebase.initializeApp(config, 'firebaseAuth');
    auth = firebase.auth(firebaseAuthApp);
    firestore =  firebase.firestore(firebaseAuthApp);
    break;
  }
}

export const firebaseAuth = auth;
export const firestoreAuth = firestore;
