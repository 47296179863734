import request from '@/api/common';

const ENDPOINT = 'v1/order_quest';

/*
  eslint-disable
  no-unused-vars
*/
export default {
  /**
   * クエスト応募者一覧取得
   * @param {*} context
   * @param {*} uuid
   * @returns
   */
  async list({ dispatch, commit, rootState, rootGetters }, queryString) {
    let endPoint = `${ENDPOINT}`;
    if (queryString) {
      endPoint += queryString;
    }
    const response = await request.get(endPoint);
    return response.data;
  }
};
/*
  eslint-disable
  no-unused-vars
*/
