import Vue from 'vue';

import crypto from '@/utils/crypto';
import { firestoreAuth } from '@/plugins/firebase_auth';
import { ROOT_AUTH_COLLECTION } from '@/assets/constants';
import { getDefaultState } from '@/store';
import store from '@/store';

/*
  eslint-disable
  no-unused-vars
*/
export default {
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken;
    document.cookie = `accessToken=${state.accessToken}`;
  },

  setAuthenticate: (state) => {
    state.authentication = true;
  },

  showLoadingOverlay(state) {
    state.loading = true;
  },

  hideLoadingOverlay(state) {
    state.loading = false;
  },

  showNotification(state, payload) {
    state.notices.push({
      id: String(state.notices.length),
      showNotice: true,
      color: payload.color,
      message: payload.message,
      multiple: payload.multiple
    });

    if (payload.timeout) {
      setTimeout(() => {
        store.commit('closeNotice', String(state.notices.length - 1));
      }, payload.timeout);
    }
  },

  closeNotice(state, id) {
    state.notices.forEach((notice) => {
      if (notice.id !== id) return;
      notice.showNotice = false;
    });
  },

  cleanNotices(state) {
    state.notices = [];
  },

  initializeState(state) {
    Object.assign(state, getDefaultState());
  },

  addProfileImg(state, payload) {
    // プロフィール画像のDataURIをstateにセット
    firestoreAuth
      .collection(ROOT_AUTH_COLLECTION)
      .doc(payload.companyCode)
      .collection(payload.token)
      .doc('profile')
      .get()
      .then(doc => {
        if (!doc.data()) { return; }

        let { value } = doc.data();
        const profileData = JSON.parse(crypto.decrypt(value, payload.companyCode));
        if (profileData && profileData.iconData) {
          Vue.set(state.profileImages, payload.token, profileData.iconData);
        }
      });
  }
};
/*
  eslint-disable
  no-unused-vars
*/
