import request from '@/api/common';

const ENDPOINT = 'v1/apply_quest';

/*
  eslint-disable
  no-unused-vars
*/
export default {
  /**
   * クエスト応募者一覧取得
   * @param {*} context
   * @param {*} uuid
   * @returns
   */
  async applies({ dispatch, commit, rootState, rootGetters }, uuid) {
    const response = await request.get(`${ENDPOINT}?quest_id=${uuid}`);

    // 応募者のプロフィール画像をstateに保存
    response.data.results.forEach(res => {
      commit('addProfileImg', { companyCode: res.userApplied.company.code, token: res.userApplied.token }, { root: true });
    });

    return response.data;
  },

  /**
   * カレントユーザーの応募クエスト一覧取得
   * @param {*} context
   */
  async applied({ dispatch, commit, rootState, rootGetters }, queryString) {
    let endPoint = `${ENDPOINT}/applied`;
    if (queryString) {
      endPoint += queryString;
    }
    const response = await request.get(endPoint);

    // クエスト発注者のプロフィール画像をstateに保存
    response.data.results.forEach(res => {
      commit('addProfileImg', { companyCode: res.quest.client.company.code, token: res.quest.client.token }, { root: true });
    });

    return response.data;
  }
};
/*
  eslint-disable
  no-unused-vars
*/
