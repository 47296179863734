import messages from '@/assets/messages';
import request from '@/api/common';

const ENDPOINT = 'v1/password_reset';

/*
  eslint-disable
  no-unused-vars
*/

export default {
  /**
   * パスワード更新
   * @param {*} context
   * @param {*} payload
   */
   async updatePassword({ dispatch, commit, rootState, rootGetters }, payload) {
    const response = await request.put(`${ENDPOINT}/update_password`, payload);

    // 完了メッセージ表示
    dispatch(
      'showNotification',
      {
        message: messages.I_PASSWORD_RESET,
        color: 'success',
        refresh: true,
        timeout: 5000
      },
      { root: true }
    );

    return response.data;
  }
};
/*
  eslint-disable
  no-unused-vars
*/
