<!-- 受注クエストの一覧 -->
<template>
  <v-card :style="styles.app" flat>
    <v-card-title>
      <div style="border-left: 5px solid #fa9f00;" class="ps-3">受注クエスト一覧</div>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="receiveListHeader"
        :items="items.results"
        :items-per-page="-1"
        :loading="_loading"
        :loading-text="'データを読み込んでいます'"
        :no-data-text="'受注したクエストはありません'"
        :style="styles.app"
        hide-default-footer
        @click:row="onClickRow"
        style="cursor: pointer;"
      >
        <template v-slot:[`item.quest.title`]="{ item }">
          <div class="py-2">
            <div class="caption text--disabled">{{ item.quest.department.name }}</div>
            <div class="font-weight-bold">{{ item.quest.title }}</div>
            <div class="caption mt-2">
              <span
                style="cursor: pointer;"
                @click.prevent.stop="disabledClickClient ? onClickRow(item) : onSelectedClient(item.quest.client)"
              >
                <v-avatar
                  class="me-1"
                  :color="getProfileImgByToken(item.quest.client.token) ? 'white' : 'blue-grey lighten-1'"
                  size="20"
                >
                  <img v-if="getProfileImgByToken(item.quest.client.token)" :src="getProfileImgByToken(item.quest.client.token)" />
                  <v-icon v-else color="white" size="20">mdi-account</v-icon>
                </v-avatar>
                <span :class="disabledClickClient ? '' : 'link'">{{ item.quest.client.nickname }}</span>
              </span>
            </div>
          </div>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          {{ getReceiveQuestStatusLabel(item.status) }}
        </template>
        <template v-slot:[`item.evaluation`]="{ item }">
          <div v-if="item.evaluation" class="d-flex align-center justify-left">
            <base-star />
            <span class="text-h6 ml-1">
              {{ Number(item.evaluation).toFixed(1) }}
            </span>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions v-if="items.next">
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        :loading="_loading"
        @click="onSeeMore"
      >
        さらに表示
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AppMethodsMixin from '@/mixins/AppMethodsMixin';
import AppStyleMixin from '@/mixins/AppStyleMixin';
import BaseStar from '@/components/atoms/BaseStar';

export default {
  name: 'ReceiveList',
  components: { BaseStar },
  mixins: [AppMethodsMixin, AppStyleMixin],
  props: {
    disabledClickClient: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => (
        {
          results: [],
          previous: null,
          next: null
        }
      )
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      receiveListHeader: [
        {
          align: 'start',
          text: 'タイトル',
          value: 'quest.title',
          sortable: false
        },
        {
          align: 'start',
          text: 'ステータス',
          value: 'status',
          sortable: false
        },
        {
          align: 'start',
          text: '発注者からの"ありがとう"',
          value: 'evaluation',
          sortable: false
        }
      ]
    };
  },
  computed: {
    _loading: {
      get() {
        return this.loading;
      },
      set(loading) {
        this.$emit('on-loading', loading);
      }
    }
  },
  methods: {
    /** 行クリック時のイベント */
    onClickRow(e) {
      this.$emit('on-click-row', e);
    },
    /** 「もっと見る」押下時のイベント */
    onSeeMore(e) {
      this.$emit('on-see-more', e);
    },
    /** 名前クリック時のイベント */
    onSelectedClient(e) {
      this.$emit('on-selected-client', e);
    }
  }
};
</script>
