<template>
  <div class="vld-parent">
    <loading
      :active="isLoading"
      :background-color="backgroundColor"
      :color="color"
      :loader="'dots'"
      :opacity="1"
      :is-full-page="true"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'AppLoadingOverlay',
  components: { Loading },
  data() {
    return {};
  },
  computed: {
    color () {
      return this.$vuetify.theme.themes.light.primary;
    },
    backgroundColor () {
      return this.$vuetify.theme.themes.light.background;
    },
    isLoading() {
      return this.$store.getters.getLoading;
    }
  }
};
</script>

<style lang="scss" scoped>
.fade-leave-active {
  transition: opacity .5s;
}
.fade-leave-to {
  opacity: 0;
}
</style>
