function get(strings, ...keys) {
  return (...values) => {
    const dict = values[values.length - 1] || {};
    const result = [strings[0]];
    keys.forEach((key, i) => {
      const value = Number.isInteger(key) ? values[key] : dict[key];
      result.push(value, strings[i + 1]);
    });
    return result.join('');
  };
}

export default {
  I_UPDATE_ITEM: get`${0}を更新しました`,

  I_REGISTRATION_QUEST: get`クエスト「${0}」の依頼に成功しました`,
  I_UPDATED_QUEST: get`クエスト「${0}」の内容を更新しました`,
  I_RELEASED_QUEST: 'クエストを公開しました',
  I_APPLIED_QUEST: get`クエスト「${0}」に応募しました`,
  I_REVOKED_QUEST: get`クエスト「${0}」を差し戻しました`,

  I_UPDATED_PROFILE_IMG: 'プロフィール画像を更新しました',

  E_UPLOAD_MAX_MEMORY_SIZE: get`アップロードできるファイルサイズの上限(${0})を超えています`,

  I_PASSWORD_RESET: 'パスワードの設定が完了しました。ログイン画面よりログインしてください',

  E_LOGIN: 'ログインに失敗しました。メールアドレスまたはパスワードをお確かめください。'
};
