export default {
  /**
    * 3桁区切りにして返す
    * @param {*} num
    * @returns
  */
  intcomma(num) {
    if (!isFinite(num)) {
      return num;
    }
    return Number(num).toLocaleString();
  }
};
