import request from '@/api/common';
/*
  eslint-disable
  no-unused-vars
*/
const ENDPOINT = 'v1/chat';

export default {
  /**
   * チャット既読化
   * @param {*} context
   */
  async read({ dispatch, commit, rootState, rootGetters }, payload) {
    const response = await request.put(`${ENDPOINT}/read`, payload);
    return response.data;
  }
};
/*
  eslint-enable
  no-unused-vars
*/
