/*
  eslint-disable
  no-unused-vars
*/
export default {
  getCategories: (state, getters, rootState, rootGetters) => {
    const questCategories = JSON.parse(localStorage.getItem('questCategories'));
    if (questCategories) {
      return questCategories;
    }
    return state.categories;
  }
};
/*
  eslint-enable
  no-unused-vars
*/
