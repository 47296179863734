/*
  eslint-disable
  no-unused-vars
*/
export default {
  getCurrentQuest: (state, getters, rootState, rootGetters) => {
    return state.currentQuest;
  }
};
/*
  eslint-enable
  no-unused-vars
*/
