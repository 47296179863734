const palx = require('palx');
const colors = palx('#fa9f00');

let lightColors = {};
let darkColors = {};

function copy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

for (let key in colors) {
  if (colors[key] instanceof Object) {
    lightColors[key] = copy(colors[key]).splice(5);
    darkColors[key] = copy(colors[key]).splice(0, 5);
  }
}

export default {
  options: {
    customProperties: true
  },
  themes: {
    light: {
      black: colors.black,
      white: colors.red[0],
      default: colors.gray[9],
      primary: colors.base,
      secondary: colors.fuschia[8],
      accent: colors.blue[7],
      info: colors.cyan[5],
      success: colors.lime[5],
      warning: colors.orange[5],
      error: colors.red[6],
      background: colors.blue[0],
      ...lightColors
    },
    dark: {
      black: colors.black,
      white: colors.red[0],
      default: colors.gray[0],
      primary: colors.base,
      secondary: colors.fuschia[8],
      accent: colors.blue[7],
      info: colors.cyan[5],
      success: colors.green[5],
      warning: colors.orange[5],
      error: colors.red[6],
      background: colors.gray[9],
      ...darkColors
    }
  }
};
