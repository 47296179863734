export default {
  computed: {
    styles() {
      let style = {
        app: {
          background: this.$vuetify.theme.themes.light.background,
          color: this.$vuetify.theme.themes.light.black
        },
        footer: {
          background: `linear-gradient(90deg, ${this.$vuetify.theme.themes.light.primary}, ${this.$vuetify.theme.themes.light.secondary}) !important`,
          color: this.$vuetify.theme.themes.light.black,
          'border-radius': '25px',
          'z-index': 10
        },
        gradient: {
          background: `linear-gradient(90deg, ${this.$vuetify.theme.themes.light.primary}, ${this.$vuetify.theme.themes.light.secondary}) !important`
        },
        secondaryGradient: {
          background: `linear-gradient(90deg, ${this.$vuetify.theme.themes.light.white}, ${this.$vuetify.theme.themes.light.black}) !important`
        }
      };
      return style;
    }
  }
};
