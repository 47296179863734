import actions from '@/store/modules/users/actions';
import getters from '@/store/modules/users/getters';
import mutations from '@/store/modules/users/mutations';

export const getDefaultState = () => ({
  authUser: {}, // FirebaseAuthのユーザー情報
  me: {} // ログインユーザー情報
});

// state >> getters >> mutations >> actionsの順で記述する
// actions >> mutationsで記述してしまうと「unknown local mutation type」が発生してハマる
export default {
  namespaced: true,
  state: getDefaultState(),
  getters,
  mutations,
  actions
};
