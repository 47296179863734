import axios from 'axios';
import store from '@/store';
import { API_HOST } from '@/assets/constants';

const getHeader = (withFile = false) => {
  let header = { Authorization: '' };

  const accessToken = store.getters.getAccessToken;
  if (accessToken) {
    header['Authorization'] = `Bearer ${accessToken}`;
  }

  if (withFile) {
    header['content-type'] = 'multipart/form-data';
  }
  return header;
};

/**
 * ステータスコードを検証
 * @param {Number} statusCode
 * @returns
 */
const checkStatusCode = async (error) => {
  // 認証エラー時はログイン画面に戻す
  if (error.response.status === 401) {
    await store.dispatch('authentications/signOut');
    store.dispatch('showNotification', {
      message: error.response.data[0].errorMsg,
      color: 'error',
      refresh: true
    });
  }
};

const generateUrl = (endpoint) => {
  return API_HOST +  endpoint;
};

export default {
  /**
   * GETリクエスト
   * @param {string} url
   */
  get: (endpoint, statusCheck = true) => {
    const url = generateUrl(endpoint);
    return axios.get(url, { headers: getHeader() }).catch((error) => {
      if (statusCheck) {
        checkStatusCode(error);
      }
      throw error;
    });
  },

  /**
   * ダウンロード用のGETリクエスト
   * @param {string} url
   */
   download: (endpoint, statusCheck = true) => {
    const url = generateUrl(endpoint);
    return axios.get(url, { headers: getHeader(), responseType: 'arraybuffer' }).catch((error) => {
      if (statusCheck) {
        checkStatusCode(error);
      }
      throw error;
    });
  },

  /**
   * POSTリクエスト
   * @param {string} url
   */
  post: (endpoint, payload, statusCheck = true, withFile = false) => {
    const url = generateUrl(endpoint);
    return axios.post(url, payload, { headers: getHeader(withFile) }).catch((error) => {
      if (statusCheck) {
        checkStatusCode(error);
      }
      throw error;
    });
  },

  /**
   * PUTリクエスト
   * @param {string} url
   */
  put: (endpoint, payload, statusCheck = true, withFile = false) => {
    const url = generateUrl(endpoint);
    return axios.put(url, payload, { headers: getHeader(withFile) }).catch((error) => {
      if (statusCheck) {
        checkStatusCode(error);
      }
      throw error;
    });
  }
};

// 共通の例外処理
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      throw error;
    }
    if (error.response.status === (404)) {
      this.$router.push({ path: '/login' });
    }
    error.response.data.forEach((e) => {
      store.dispatch('showNotification', {
        message: e.errorMsg,
        color: 'error',
        refresh: true,
        timeout: 5000
      });
    });
    throw error;
  }
);
