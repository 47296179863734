import request from '@/api/common';
import messages from '@/assets/messages';
import router from '@/router';

const ENDPOINT = 'v1/quest';

/*
  eslint-disable
  no-unused-vars
*/
export default {
  /**
   * クエスト登録内容をstateに保存
   * @param {*} context
   * @param {*} payload
   */
  toConfirm({ dispatch, commit, rootState, rootGetters }, payload) {
    commit('setCurrentQuest', payload);
  },

  /**
   * クエスト登録実行
   * @param {*} context
   * @param {*} payload
   * @returns
   */
  async create({ dispatch, commit, rootState, rootGetters }, payload) {
    const response = await request.post(ENDPOINT, payload);

    // クエスト一覧に遷移
    router.push({ name: 'SearchQuest' });

    // 登録データを破棄
    commit('setCurrentQuest', null);

    // 完了メッセージ表示
    dispatch(
      'showNotification',
      {
        message: messages.I_REGISTRATION_QUEST(response.data.title),
        color: 'success',
        refresh: true,
        timeout: 5000
      },
      { root: true }
    );
  },

  /**
   * 更新
   * @param {*} context
   * @param {*} payload
   */
   async update({ dispatch, commit, rootState, rootGetters }, payload) {
    const response = await request.put(`${ENDPOINT}/${payload.questId}`, payload.quest);

    // クエスト一覧に遷移
    router.push({ name: 'SearchQuest' });

    // 更新データを破棄
    commit('setDefault', 'currentQuest');

    // 完了メッセージ表示
    dispatch(
      'showNotification',
      {
        message: messages.I_UPDATED_QUEST(response.data.title),
        color: 'success',
        refresh: true,
        timeout: 5000
      },
      { root: true }
    );
  },

  /**
   * クエスト一覧取得
   * @param {*} context
   * @returns
   */
   async list({ dispatch, commit, rootState, rootGetters }, queryString) {
    let endPoint = ENDPOINT;
    if (queryString) {
      endPoint += queryString;
    }
    const response = await request.get(endPoint);

    // 契約者のプロフィール画像をstateに保存
    response.data.results.forEach(res => {
      commit('addProfileImg', { companyCode: res.client.company.code,  token: res.client.token }, { root: true });
    });

    return response.data;
  },

  /**
   * クエスト詳細取得
   * @param {*} context
   * @param {*} uuid
   * @returns
   */
   async retrieve({ dispatch, commit, rootState, rootGetters }, uuid) {
    const response = await request.get(`${ENDPOINT}/${uuid}`);
    return response.data;
  },
 
  /**
   * 応募者と契約
   * @param {*} context
   * @param {*} payload
   */
  async contract({ dispatch, commit, rootState, rootGetters }, payload) {
    await request.post(`${ENDPOINT}/${payload.questId}/contract?user_id=${payload.userId}`);
  },

  /**
   * クエスト公開
   * @param {*} context
   * @param {*} questId
   * @param {*} payload
   */
  async release({ dispatch, commit, rootState, rootGetters }, payload) {
    const response = await request.put(`${ENDPOINT}/${payload.questId}/release`, { reward: payload.reward });
    // 完了メッセージ表示
    dispatch(
      'showNotification',
      {
        message: messages.I_RELEASED_QUEST,
        color: 'success',
        refresh: true,
        timeout: 5000
      },
      { root: true }
    );
    return response.data;
  },

  /**
   * クエスト応募
   * @param {*} context
   * @param {*} uuid
   */
  async apply({ dispatch, commit, rootState, rootGetters }, uuid) {
    const response = await request.post(`${ENDPOINT}/${uuid}/apply`);
    // 完了メッセージ表示
    dispatch(
      'showNotification',
      {
        message: messages.I_APPLIED_QUEST(response.data.title),
        color: 'success',
        refresh: true,
        timeout: 5000
      },
      { root: true }
    );
    return response.data;
  },

  /**
   * クエスト差し戻し
   * @param {*} context
   * @param {*} uuid
   */
   async revoke({ dispatch, commit, rootState, rootGetters }, payload) {
    const response = await request.put(`${ENDPOINT}/${payload.questId}/revoke`, { comment: payload.comment });
    // 完了メッセージ表示
    dispatch(
      'showNotification',
      {
        message: messages.I_REVOKED_QUEST(response.data.questTitle),
        color: 'success',
        refresh: true,
        timeout: 5000
      },
      { root: true }
    );
    return response.data;
  },

  /**
   * 最新の差し戻しレコードを取得
   * @param {*} param0 
   * @param {*} queryString
   */
  async latestRevoke({ dispatch, commit, rootState, rootGetters }, questId) {
    const response = await request.get(`${ENDPOINT}/${questId}/revoke/latest`);
    return response.data;
  },

  /**
   * クエストの契約者を一覧取得
   * @param {*} context
   * @param {*} questId
   */
  async challengers({ dispatch, commit, rootState, rootGetters }, questId) {
    const response = await request.get(`${ENDPOINT}/${questId}/challengers`);

    // 契約者のプロフィール画像をstateに保存
    response.data.forEach(data => {
      commit('addProfileImg', { companyCode: data.challenger.company.code, token: data.challenger.token }, { root: true });
    });

    return response.data;
  }
};
/*
  eslint-enable
  no-unused-vars
*/
