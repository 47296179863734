export default {
  showNotification({ commit }, payload) {
    if (payload.refresh) this.dispatch('cleanNotices');
    commit('showNotification', payload);
  },
  closeNotice({ commit }, id) {
    commit('closeNotice', id);
  },
  cleanNotices({ commit }) {
    commit('cleanNotices');
  }
};
