import actions from '@/store/modules/user_notifications/actions';
import getters from '@/store/modules/user_notifications/getters';
import mutations from '@/store/modules/user_notifications/mutations';

export const getDefaultState = () => ({
  latest: [],
  unreadCount: undefined
});

// state >> getters >> mutations >> actionsの順で記述する
// actions >> mutationsで記述してしまうと「unknown local mutation type」が発生してハマる
export default {
  namespaced: true,
  state: getDefaultState(),
  getters,
  mutations,
  actions
};
