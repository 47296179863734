<template>
  <v-card
    :class="className"
    :flat="flat"
    :style="`border: solid ${$vuetify.theme.themes.light.primary}; border-width: 0 7px 7px 7px;`"
    tile
  >
    <v-card-title class="primary justify-center white--text font-weight-bold py-1" style="height: 40px;">
      <slot name="title" />
    </v-card-title>
    <v-card-text
      class="d-flex align-center justify-center py-0 px-0"
      :style="`height: ${contentHeight}`"
    >
      <base-progress-circular v-if="loading" />
      <slot v-else name="content" />
    </v-card-text>
  </v-card>
</template>

<script>
import BaseProgressCircular from '@/components/atoms/BaseProgressCircular';

export default {
  name: 'BasePrimaryCard',
  components: { BaseProgressCircular },
  props: {
    className: {
      type: String,
      default: ''
    },
    contentHeight: {
      type: String,
      default: '70px'
    },
    flat: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>
