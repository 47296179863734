import { getDefaultState } from '@/store/modules/quest_categories';

export default {
  initializeState(state) {
    Object.assign(state, getDefaultState());
  },

  setCategories(state, payload) {
    localStorage.setItem('questCategories', JSON.stringify(payload));
    state.categories = payload;
  }
};
