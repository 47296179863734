import store from '@/store';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: {
      showHeader: false,
      title: 'ログイン'
    }
  },
  {
    path: '/',
    redirect: { name: 'Login' }
  },

  /** マイページ */
  {
    path: '/mypage',
    name: 'MyPage',
    component: () => import('@/components/pages/mypage/Detail.vue'),
    meta: {
      title: 'マイページ',
      showHeader: true
    }
  },

  /** 承認待ちクエスト */
  {
    path: '/search_waiting_quest',
    component: () => import('@/components/pages/search_quest/Index.vue'),
    children: [
      {
        path: '',
        name: 'SearchWaitingQuest',
        component: () => import('@/components/pages/search_quest/WaitingList.vue'),
        meta: {
          title: '承認待ちクエスト',
          showHeader: true
        }
      },
      {
        path: ':questId',
        name: 'WaitingQuestDetail',
        component: () => import('@/components/pages/search_quest/Detail.vue'),
        meta: {
          title: 'クエスト詳細',
          showHeader: true
        }
      }
    ]
  },

  /** クエストを探す */
  {
    path: '/search_quest',
    component: () => import('@/components/pages/search_quest/Index.vue'),
    children: [
      {
        path: '',
        name: 'SearchQuest',
        component: () => import('@/components/pages/search_quest/List.vue'),
        meta: {
          title: 'クエストを探す',
          showHeader: true
        }
      },
      {
        path: ':questId',
        name: 'QuestDetail',
        component: () => import('@/components/pages/search_quest/Detail.vue'),
        meta: {
          title: 'クエスト詳細',
          showHeader: true
        }
      }
    ]
  },

  /** クエストを依頼する */
  {
    path: '/order_quest',
    component: () => import('@/components/pages/order_quest/Index.vue'),
    children: [
      {
        path: '',
        name: 'OrderQuest',
        component: () => import('@/components/pages/order_quest/Create.vue'),
        meta: {
          title: 'クエストを依頼する',
          showHeader: true
        }
      },
      {
        path: 'confirm',
        name: 'CreateConfirm',
        component: () => import('@/components/pages/order_quest/CreateConfirm.vue'),
        meta: {
          title: 'クエスト依頼内容確認',
          showHeader: true
        }
      },
      {
        path: ':questId/edit',
        name: 'EditQuest',
        component: () => import('@/components/pages/order_quest/Edit.vue'),
        meta: {
          title: 'クエスト内容編集',
          showHeader: true
        }
      },
      {
        path: ':questId/edit/confirm',
        name: 'ConfirmEdit',
        component: () => import('@/components/pages/order_quest/EditConfirm.vue'),
        meta: {
          title: 'クエスト編集内容確認',
          showHeader: true
        }
      }
    ]
  },

  /** お知らせ */
  {
    path: '/notification',
    name: 'Notification',
    component: () => import('@/components/pages/notification/List.vue'),
    meta: {
      title: '全てのお知らせ',
      showHeader: true
    }
  },

  /** ユーザープロフィール */
  {
    path: '/profile',
    component: () => import('@/components/pages/profile/Index.vue'),
    children: [
      {
        path: 'edit',
        name: 'ProfileEdit',
        component: () => import('@/components/pages/profile/Edit.vue'),
        meta: {
          title: 'プロフィール編集',
          showHeader: true
        }
      }
    ]
  },

  /** クエスト進行状況 */
  {
    path: '/contract',
    component: () => import('@/components/pages/contract/Index.vue'),
    children: [
      {
        path: ':contractId',
        name: 'ContractDetail',
        component: () => import('@/components/pages/contract/Detail.vue'),
        meta: {
          title: '進行状況',
          showHeader: true
        }
      }
    ]
  },

  /** ヘルプ */
  {
    path: '/help',
    name: 'Help',
    component: () => import('@/components/pages/help/index/IndexContainer.vue'),
    meta: {
      title: 'ヘルプ',
      showHeader: true
    }
  },

  /** パスワードリセット */
  {
    path: '/password/reset/:token',
    name: 'PasswordReset',
    component: () => import('@/components/pages/password_reset/index/IndexContainer.vue'),
    meta: {
      title: 'パスワード設定',
      showHeader: false
    }
  },
  /** メンテナンス中 */
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: () => import('@/components/pages/Maintenance.vue'),
    meta: {
      title: 'メンテナンス中です',
      showHeader: false
    }
  },
  /** 404 NotFound */
  {
    path: '*',
    name: 'NotFound',
    component: () => import('@/components/pages/NotFound.vue')
  }

];

/*
  eslint-disable
  no-unused-vars
*/
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }; // ページスクロールをトップへ
  }
});

/**
 * 画面が切り替わるたびに下記を実施する
 * ・noticeを空にする
 * ・新着お知らせ取得（例外は無視する）
 */
router.beforeEach((to, from, next) => {
  store.dispatch('cleanNotices');
  store.dispatch('userNotifications/latest').then(() => {}).catch(() => {});
  next();
});

/**
 * 遷移後にタイトルを設定する
 */
router.afterEach((to, from) => {
  document.title = to.meta.title||'QuestImagineering';
});
/*
  eslint-disable
  no-unused-vars
*/

export default router;
