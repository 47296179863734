import request from '@/api/common';

const ENDPOINT = 'v1/user_notification';

/*
  eslint-disable
  no-unused-vars
*/
export default {
  // お知らせ一覧取得
  async list({ dispatch, commit, rootState, rootGetters }, queryString) {
    let endPoint = ENDPOINT;
    if (queryString) {
      endPoint += queryString;
    }
    const response = await request.get(endPoint);
    return response.data;
  },

  // 新着お知らせ取得
  async latest({ dispatch, commit, rootState, rootGetters }) {
    const response = await request.get(`${ENDPOINT}/latest`, false);
    commit('setLatest', response.data.results); // 新着お知らせデータ保存
    commit('setUnreadCount', response.data.count); // 未読のお知らせ件数保存
  },

  // お知らせ既読
  async read({ dispatch, commit, rootState, rootGetters }, notificationId) {
    await request.put(`${ENDPOINT}/${notificationId}/read`);
  }
};
/*
  eslint-enable
  no-unused-vars
*/
