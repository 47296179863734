<template>
  <div>
    <span v-for="(notice, index) in notices" :key="notice.id">
      <v-system-bar
        v-if="notice.showNotice"
        :color="notice.color"
        class="notice my-2 px-5 d-flex py-8 white--text"
        :class="notice.multiple ? 'notice-multiple' : 'notice-overlap'"
        dark
      >
        <strong class="font-weight-bold text-h6 pe-5">{{ notice.message }}</strong>
        <v-btn
          style="position: absolute; right: 5px"
          icon
          @click="onClose(notice.id)"
        >
          <v-icon class="mx-auto">mdi-close</v-icon>
        </v-btn>
      </v-system-bar>
      <span v-if="index !== notices.length - 1">
        <br />
        <br />
        <br />
        <br />
      </span>
    </span>
  </div>
</template>

<script>
export default {
  computed: {
    notices() {
      return this.$store.state.notices;
    }
  },
  methods: {
    onClose: function (id) {
      this.$store.dispatch('closeNotice', id);
    }
  }
};
</script>

<style lang="scss" scoped>
.notice {
  width: 90%;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 10px;
  z-index: 5;

  opacity: 0;
  animation-name: notice;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes notice {
  100% {
    opacity: 1;
  }
}

.notice-overlap {
  position: fixed;
  top: 0;
}
.notice-multiple {
  position: fixed;
}
</style>
