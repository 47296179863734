import request from '@/api/common';

const ENDPOINT = 'v1/user';

/*
  eslint-disable
  no-unused-vars
*/
export default {
  /**
   * ログインユーザー情報取得
   * @param {*} context
   */
  async me({ dispatch, commit, rootState, rootGetters }) {
    const response = await request.get(`${ENDPOINT}/me`, false);
    if (response) {
      commit('setAuthenticate', null, { root: true });
      commit('setMe', response.data);
      commit('addProfileImg', { companyCode: response.data.company.code, token: response.data.token }, { root: true });
    }
  },

  /**
   * ユーザーの受発注実績取得
   * @param {*} context
   * @returns
   */
   async summary({ dispatch, commit, rootState, rootGetters }, queryString) {
    let endPoint = `${ENDPOINT}/summary`;
    if (queryString) {
      endPoint += queryString;
    }
    const response = await request.get(endPoint);
    return response.data;
  }
};
/*
  eslint-enable
  no-unused-vars
*/
