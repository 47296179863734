import { getDefaultState } from '@/store/modules/user_notifications';

export default {
  initializeState(state) {
    Object.assign(state, getDefaultState());
  },

  setLatest(state, notifications) {
    state.latest = notifications;
  },

  setUnreadCount(state, count) {
    state.unreadCount = count;
  }
};
