<template>
  <v-card :style="styles.app" flat>
    <v-card-title>
      <div style="border-left: 5px solid #fa9f00;" class="ps-3">発注クエスト一覧</div>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="orderListHeader"
        :items="items.results"
        :items-per-page="-1"
        :loading="_loading"
        :loading-text="'データを読み込んでいます'"
        :no-data-text="'発注したクエストはありません'"
        :style="styles.app"
        hide-default-footer
        @click:row="onClickRow"
        style="cursor: pointer;"
      >
        <template v-slot:[`item.title`]="{ item }">
          <div class="py-2">
            <div class="font-weight-bold">{{ item.title }}</div>
          </div>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <span>{{ expiredLabel(item.deadline)||getQuestStatusLabel(item.status) }}</span>
        </template>
        <template v-slot:[`item.evaluation`]="{ item }">
          <div v-if="item.evaluation" class="d-flex align-center justify-left">
            <base-star />
            <span class="text-h6 ml-1">
              {{ Number(item.evaluation).toFixed(1) }}
            </span>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions v-if="items.next">
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        :loading="_loading"
        @click="onSeeMore"
      >
        さらに表示
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AppMethodsMixin from '@/mixins/AppMethodsMixin';
import AppStyleMixin from '@/mixins/AppStyleMixin';
import BaseStar from '@/components/atoms/BaseStar';

export default {
  name: 'OrderList',
  components: { BaseStar },
  mixins: [AppMethodsMixin, AppStyleMixin],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => (
        {
          results: [],
          previous: null,
          next: null
        }
      )
    }
  },
  data() {
    return {
      orderListHeader: [
        {
          align: 'start',
          text: 'タイトル',
          value: 'title',
          sortable: false
        },
        {
          align: 'start',
          text: 'ステータス',
          value: 'status',
          sortable: false
        },
        {
          align: 'start',
          text: '受注者からの"ありがとう"',
          value: 'evaluation',
          sortable: false
        }
      ]
    };
  },
  computed: {
    _loading: {
      get() {
        return this.loading;
      },
      set(loading) {
        this.$emit('on-loading', loading);
      }
    }
  },
  methods: {
    /** 行クリック時のイベント */
    onClickRow(e) {
      this.$emit('on-click-row', e);
    },
    /** 「もっと見る」押下時のイベント */
    onSeeMore(e) {
      this.$emit('on-see-more', e);
    },
    /** 名前クリック時のイベント */
    onSelectedClient(e) {
      this.$emit('on-selected-client', e);
    }
  }
};
</script>
