import { getDefaultState } from '@/store/modules/users';

export default {
  initializeState(state) {
    Object.assign(state, getDefaultState());
  },

  setAuthUser(state, authUser) {
    state.authUser = authUser;
  },

  setMe(state, me) {
    state.me = me;
  }
};
