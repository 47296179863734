import crypto from '@/utils/crypto';
import { firestoreAuth } from '@/plugins/firebase_auth';
import { ROOT_AUTH_COLLECTION } from '@/assets/constants';
import messages from '@/assets/messages';
import request from '@/api/common';

const ENDPOINT = 'v1/userprofile';

/*
  eslint-disable
  no-unused-vars
*/
export default {
  /**
   * ユーザープロフィール取得
   * @param {*} context
   */
  async get({ dispatch, commit, rootState, rootGetters }, userId) {
    let endpoint = ENDPOINT;
    if (userId) {
      endpoint += `?token=${userId}`;
    }
    const response = await request.get(endpoint);
    return response.data;
  },

  /**
   * ユーザープロフィール更新
   * @param {*} context
   * @param {*} payload
   */
  async update({ dispatch, commit, rootState, rootGetters }, payload) {
    const response = await request.put(ENDPOINT, payload);

    // プロフィールの更新に成功した場合はユーザー情報を取得し直す（ニックネームが変わっている可能性があるため）
    dispatch('users/me', null, { root: true });

    // 完了メッセージ表示
    dispatch(
      'showNotification',
      {
        message: messages.I_UPDATE_ITEM('プロフィール'),
        color: 'success',
        refresh: true,
        timeout: 5000
      },
      { root: true }
    );

    // Firestoreにも保存
    try {
      const userinfo = rootGetters['users/getMe'];
      const profileData = {
        email: response.email,
        lastName: userinfo.lastName,
        firstName: userinfo.firstName,
        birthday: response.birthday,
        summary: response.summary,
        companyCode: userinfo.company.code
      };
      const cryptedData = crypto.encrypt(JSON.stringify(profileData), userinfo.company.code);
      firestoreAuth
        .collection(ROOT_AUTH_COLLECTION)
        .doc(userinfo.company.code)
        .collection(userinfo.token)
        .doc('profile')
        .set({ value: cryptedData });
    } catch {
      // Firestoreへの保存に失敗した場合は何もしない
    }

    return response.data;
  },

  /**
   * プロフィール画像アップロード
   * @param {*} context
   * @param {*} payload 
   */
  async uploadProfileImg({ dispatch, commit, rootState, rootGetters }, payload) {
    const response = await request.put(`${ENDPOINT}/upload_profile_img`, payload, true, true);
    const responseData = response.data;

    // storeのプロフィール画像を更新
    const userinfo = rootGetters['users/getMe'];

    // 完了メッセージ表示
    dispatch(
      'showNotification',
      {
        message: messages.I_UPDATED_PROFILE_IMG,
        color: 'success',
        refresh: true,
        timeout: 5000
      },
      { root: true }
    );

    return responseData;
  }
};
/*
  eslint-enable
  no-unused-vars
*/
