<template>
  <v-fab-transition transition="scale-transition" origin="center center">
    <v-btn
      v-scroll="onScroll"
      v-show="btnScrollTop"
      class="mb-15"
      fab
      dark
      fixed
      bottom
      right
      color="primary"
      style="z-index: 10;"
      :small="$vuetify.breakpoint.xsOnly ? true : false"
      :large="$vuetify.breakpoint.xsOnly ? false : true"
      @click="toTop"
    >
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
  </v-fab-transition>
</template>

<script>
export default {
  name: 'AppScrollTopBtn',
  data() {
    return {
      btnScrollTop: false
    };
  },
  methods: {
    /** 画面スクロールによってボタンの表示/非表示を切り替える */
    onScroll (e) {
      if (typeof window === 'undefined') return;
      const top = window.pageYOffset ||   e.target.scrollTop || 0;
      this.btnScrollTop = top > 300;
    },
    /** 画面TOPへスクロール */
    toTop () {
      this.$vuetify.goTo(0);
    }
  }
};
</script>
