import firebase from 'firebase/app';
import { firebaseAuth } from '@/plugins/firebase_auth';
import router from '@/router';

import messages from '@/assets/messages';

/*
  eslint-disable
  no-unused-vars
*/
export default {
  /**
   * メールアドレスとパスワードでログイン
   * @param {*} context
   * @param {*} payload
   */
  async signIn({ dispatch, commit, rootState, rootGetters }, payload) {
    try {
      const response = await firebaseAuth.signInWithEmailAndPassword(payload.email, payload.password);
      const idToken = await response.user.getIdToken();
      commit('setAccessToken', idToken, { root: true });

    } catch {
      dispatch(
        'showNotification',
        {
          message: messages.E_LOGIN,
          color: 'error',
          refresh: true,
          timeout: 5000
        },
        { root: true }
      );
      return; // ログイン失敗時は何もしない
    }

    commit('setAuthenticate', null, { root: true });
    commit('users/setAuthUser', firebaseAuth.currentUser, { root: true });
    await this.dispatch('users/me'); // DBからユーザー情報を取得

    // マイページに遷移
    router.push({ name: 'MyPage' });
  },

  /**
   * Googleログイン
   * @param {*} context
   * @param {*} payload
   */
  signInWithGoogle({ dispatch, commit, rootState, rootGetters }, payload) {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('email');
    provider.setCustomParameters({ prompt: 'select_account' });
    firebaseAuth
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then((success) => {
        return firebaseAuth.signInWithRedirect(provider);
      });
  },

  /**
   * ログイン後のコールバック
   * @param {*} context
   */
  signInCallback({ dispatch, commit, rootState, rootGetters }) {
    commit('showLoadingOverlay', null, { root: true }); // ローディング表示
    firebaseAuth.onAuthStateChanged(async () => {
      try{
        const redirectResult = await firebaseAuth.getRedirectResult();
        if (redirectResult.credential) {
          const idToken = await firebaseAuth.currentUser.getIdToken();
          commit('setAccessToken', idToken, { root: true });
          commit('setAuthenticate', null, { root: true });
          commit('users/setAuthUser', firebaseAuth.currentUser, { root: true });
          this.dispatch('users/me'); // DBからユーザー情報を取得

          // マイページに遷移
          router.push({ name: 'MyPage' });
        }

      // エラー発生時はログイン画面へ
      } catch (error) {
        this.dispatch('signOut');

      // ローディング必ず非表示にする
      } finally { 
        commit('hideLoadingOverlay', null, { root: true }); // ローディング表示
      }
    });
  },

  /**
   * ログアウト
   * @param {*} context
   */
  async signOut({ dispatch, commit, rootState, rootGetters }) {
    await firebaseAuth.signOut();

    // Cookieの削除
    document.cookie = 'accessToken=; max-age=0';

    // 各種モジュールのstateを初期化
    commit('initializeState');
    commit('initializeState', null, { root: true });
    commit('quests/initializeState', null, { root: true });
    commit('questCategories/initializeState', null, { root: true });
    commit('userNotifications/initializeState', null, { root: true });

    // ログイン画面へ
    if (window.location.pathname !== '/login') {
      router.push({ name: 'Login' });
    }
  }
};
/*
  eslint-enable
  no-unused-vars
*/
