import actions from '@/store/modules/quests/actions';
import getters from '@/store/modules/quests/getters';
import mutations from '@/store/modules/quests/mutations';

export const getDefaultState = () => ({
  currentQuest: null
});

export default {
  namespaced: true,
  state: getDefaultState,
  getters,
  mutations,
  actions
};
