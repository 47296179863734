import request from '@/api/common';
/*
  eslint-disable
  no-unused-vars
*/
const ENDPOINT = 'v1/department';

export default {
  /**
   * 自社の部署を一覧取得
   * @param {*} context
   */
  async list({ dispatch, commit, rootState, rootGetters }) {
    const response = await request.get(ENDPOINT);
    return response.data;
  }
};
/*
  eslint-enable
  no-unused-vars
*/
