import { getDefaultState } from '@/store/modules/authentications';

export default {
  initializeState(state) {
    Object.assign(state, getDefaultState());
  },

  setSigningInProcess(state) {
    state.signingInProcess = true;
  },

  setSigningInProcessCompleted(state) {
    state.signingInProcess = false;
  }
};
