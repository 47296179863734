<template>
  <v-footer padless class="pa-3" :style="styles.app">
    <v-col :style="styles.footer" class="outset" cols="12">
      <v-row>
        <!-- <v-col cols="auto">
          <router-link to="" class="mr-8">
            <small>・利用規約</small>
          </router-link>
        </v-col> -->
        <v-col class="white--text text-right">
          <small>
            ©︎ {{ new Date().getFullYear() }} Fabeee Inc. All rights reserved.
          </small>
        </v-col>
      </v-row>
    </v-col>
  </v-footer>
</template>

<script>
import AppStyleMixin from '@/mixins/AppStyleMixin';

export default {
  name: 'AppFooter',
  mixins: [AppStyleMixin]
};
</script>
