// APIホスト
export const API_HOST = process.env.VUE_APP_API_HOST;

// クエストステータス
export const QUEST_STATUS = {
  waiting: { label: '審査中', value: 100 }, // 審査中
  revoke: { label: '差し戻し', value: 200 }, // 差し戻し
  recruitment: { label: '募集中', value: 300 }, // 募集中
  close: { label: '応募終了', value: 400 } // 応募終了
};

// 受注クエストステータス
export const RECEIVE_QUEST_STATUS = {
  inProgress: { label: '進行中', value: 100 },
  finished: { label: '完了', value: 200 },
  suspension: { label: '中断', value: 300 }
};

// ユーザー権限
export const ROLES = {
  member: 100,
  depManager: 200,
  executive: 300,
  superUser:999
};

// クエスト受注後の進行状況
export const RECEIVE_QUEST_STEP = {
  inProgress: { label: 'クエスト遂行中', value: 100 },
  delivery: { label: '納品', value: 200 },
  inspection: { label: '検品中', value: 300 },
  finished: { label: 'クエスト完了', value: 400 },
  evaluation: { label: '評価', value: 500 }
};

// 性別
export const GENDER = {
  male: 1,
  female: 2
};

export const ROOT_QUEST_COLLECTION = 'services/quest_imagineering/';
export const ROOT_AUTH_COLLECTION =  'auth_data_collections';
