import actions from '@/store/modules/quest_categories/actions';
import getters from '@/store/modules/quest_categories/getters';
import mutations from '@/store/modules/quest_categories/mutations';

export const getDefaultState = () => ({
  categories: []
});

export default {
  namespaced: true,
  state: getDefaultState,
  getters,
  mutations,
  actions
};
