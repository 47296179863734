import actions from '@/store/actions';
import applyQuests from '@/store/modules/apply_quests';
import authentications from '@/store/modules/authentications';
import chats from '@/store/modules/chats';
import departments from '@/store/modules/departments';
import getters from '@/store/getters';
import mutations from '@/store/mutations';
import orderQuests from '@/store/modules/order_quests';
import passwordResets from '@/store/modules/password_resets';
import quests from '@/store/modules/quests';
import questCategories from '@/store/modules/quest_categories';
import receiveQuests from '@/store/modules/receive_quests';
import userNotifications from '@/store/modules/user_notifications';
import userprofiles from '@/store/modules/userprofiles';
import users from '@/store/modules/users';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const getDefaultState = () => ({
  accessToken: null,
  authentication: false,
  loading: false,
  notices: [],
  profileImages: {}
});

export default new Vuex.Store({
  state: getDefaultState(),
  getters,
  mutations,
  actions,
  modules: {
    applyQuests,
    authentications,
    chats,
    departments,
    orderQuests,
    passwordResets,
    quests,
    questCategories,
    receiveQuests,
    userNotifications,
    userprofiles,
    users
  }
});
