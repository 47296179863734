<template>
  <v-progress-circular
    :color="color"
    :indeterminate="indeterminate"
  >
  </v-progress-circular>
</template>

<script>
export default {
  name: 'BaseProgressCircular',
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    indeterminate: {
      type: Boolean,
      default: true
    }
  }
};
</script>
