const CryptoJS = require('crypto-js');

const BLOCK_SIZE = 16;
const KEY_SIZE = 32;

export default {
  createPassword(password) {
    return (password + '0'.repeat(KEY_SIZE)).substr(0, KEY_SIZE);
  },
  /** 暗号化 */
  encrypt(plainText, password) {
    const key = CryptoJS.enc.Utf8.parse(this.createPassword(password));
    const iv = CryptoJS.lib.WordArray.random(BLOCK_SIZE);
    const encrypted = CryptoJS.AES.encrypt(plainText, key, { iv, padding: CryptoJS.pad.Pkcs7 });
    return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
  },
  /** 複合化 */
  decrypt(encryptedText, password) {
    let ciphertext = CryptoJS.enc.Base64.parse(encryptedText);
    const key = CryptoJS.enc.Utf8.parse(this.createPassword(password));
    let iv = ciphertext.clone();
    iv.sigBytes = BLOCK_SIZE;
    iv.clamp();
    ciphertext.words.splice(0, 4);
    ciphertext.sigBytes -= BLOCK_SIZE;
    const decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, key, { iv });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
};
