import request from '@/api/common';
/*
  eslint-disable
  no-unused-vars
*/
export default {
  /**
   * クエストカテゴリーを一覧取得
   * @param {*} context
   */
  async list({ dispatch, commit, rootState, rootGetters }) {
    const response = await request.get('v1/quest_category');
    commit('setCategories', response.data);
  }
};
/*
  eslint-enable
  no-unused-vars
*/
