<template>
  <v-dialog
    v-model="_show"
    transition="dialog-bottom-transition"
    max-width="800"
    :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
  >
    <v-card
      tile
      :style="styles.app"
    >
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click.stop="onCancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="font-weight-bold">{{ getUserInfoProp.nickname }}さんの公開プロフィール</v-toolbar-title>
      </v-toolbar>
      <!-- <v-card-text v-if="!$vuetify.breakpoint.smAndDown">
        ※キーボードの「Esc」キー押下で公開プロフィールを閉じることができます
      </v-card-text> -->
      <v-card-text>
        <v-container class="text-center mb-6 mt-10">
          <v-row justify="space-around">
            <v-avatar
              :color="getProfileImgByToken(getUserInfoProp.token) ? 'white' : 'blue-grey lighten-1'"
              size="80"
            >
              <img v-if="getProfileImgByToken(getUserInfoProp.token)" :src="getProfileImgByToken(getUserInfoProp.token)" />
              <v-icon v-else color="white" size="60">mdi-account</v-icon>
            </v-avatar>
          </v-row>

          <v-row>
            <v-col>
              <div class="text--disabled">{{ getUserInfoProp.department.name }}</div>
              <div>{{ getUserInfoProp.nickname }}</div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card
                class="outset"
                :style="styles.app"
                flat
              >
                <v-expansion-panels v-model="showPR" flat>
                  <v-expansion-panel class="px-5" :style="styles.app">
                    <v-expansion-panel-header
                      class="my-2"
                      style="border-left: 5px solid #fa9f00;"
                      disable-icon-rotate
                      ripple
                      @click="expandSelfIntroduction = !expandSelfIntroduction;"
                    >
                      <span class="font-weight-bold">自己紹介</span>
                      <template v-slot:actions>
                        <span class="primary--text caption">{{ expandSelfIntroduction ? '閉じる' : '表示する' }}</span>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="ps-3 pe-10 text-start"
                      v-html="nl2br(userprofile.summary)"
                    />
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card>

              <v-divider class="my-5" />

              <v-card
                class="outset rounded-xl"
                :style="styles.app"
              >
                <v-tabs
                  v-model="tab"
                  slider-size="3"
                  grow
                  hide-slider
                >
                  <v-tab
                    v-for="item in items"
                    :key="item.tab"
                  >
                    {{ item.tab }}
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <v-tab-item
                    v-for="item in items"
                    :key="item.name"
                  >
                    <!-- ■■■■■■■■■■■■■■ 受注実績タブ ■■■■■■■■■■■■■■ -->
                    <v-card
                      v-if="item.name === 'receive'"
                      :style="styles.app"
                      flat
                    >
                      <v-card-text>
                        <v-row dense>
                          <v-col cols="12" md="12">
                            <v-row justify="center">
                              <v-col md="4">
                                <primary-card
                                  :class-name="'mb-3'"
                                  :loading="summary.totalApplyCount === null"
                                >
                                  <template #title>
                                    受注実績
                                  </template>
                                  <template #content>
                                    <span class="text-h4 font-weight-bold me-1">{{ summary.totalReceiveCount }}</span>
                                    <span class="text-h5 font-weight-bold pt-1">件</span>
                                  </template>
                                </primary-card>
                              </v-col>
                              <v-col cols="12" md="4">
                                <primary-card
                                  :class-name="'mb-3'"
                                  :loading="summary.totalReceiveLate === null"
                                >
                                  <template #title>
                                    受注率
                                  </template>
                                  <template #content>
                                    <span class="text-h4 font-weight-bold me-1">{{ Number(summary.totalReceiveLate).toFixed(1) }}</span>
                                    <span class="text-h5 font-weight-bold pt-2">%</span>
                                  </template>
                                </primary-card>
                              </v-col>
                              <v-col cols="12" md="4">
                                <primary-card
                                  :class-name="'mb-3'"
                                  :loading="summary.totalReceiveLate === null"
                                >
                                  <template #title>
                                    ありがとう
                                  </template>
                                  <template #content>
                                    <base-star />
                                    <template v-if="summary.totalChallengerEvaluation === null">
                                      <app-progress-circular />
                                    </template>
                                    <template v-else>
                                      <span class="font-weight-bold text-h4 ml-1">
                                        {{ Number(summary.totalChallengerEvaluation).toFixed(1) }}
                                      </span>
                                    </template>
                                  </template>
                                </primary-card>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>

                        <receive-list
                          :disabled-click-client="true"
                          :items="receiveList"
                          :loading="dataTableLoading.receive"
                          @on-click-row="onClickReceiveListRow"
                          @on-loading="onLoading({ receive: $event })"
                          @on-see-more="onSeeMoreReceiveList"
                        />
                      </v-card-text>
                    </v-card>

                    <!-- ■■■■■■■■■■■■■■ 発注実績タブ ■■■■■■■■■■■■■■ -->
                    <v-card
                      v-if="item.name === 'order'"
                      :style="styles.app"
                      flat
                    >
                      <v-card-text>
                        <v-row dense>
                          <v-col cols="12" md="12">
                            <v-row justify="center">
                              <v-col md="4">
                                <primary-card
                                  :class-name="'mb-3'"
                                  :loading="summary.totalOrderCount === null"
                                >
                                  <template #title>
                                    発注実績
                                  </template>
                                  <template #content>
                                    <span class="text-h4 font-weight-bold me-1">{{ summary.totalOrderCount }}</span>
                                    <span class="text-h5 font-weight-bold pt-1">件</span>
                                  </template>
                                </primary-card>
                              </v-col>
                              <v-col cols="12" md="4">
                                <primary-card
                                  :class-name="'mb-3'"
                                  :loading="summary.totalOrderLate === null"
                                >
                                  <template #title>
                                    発注率
                                  </template>
                                  <template #content>
                                    <span class="text-h4 font-weight-bold me-1">{{ Number(summary.totalOrderLate).toFixed(1) }}</span>
                                    <span class="text-h5 font-weight-bold pt-2">%</span>
                                  </template>
                                </primary-card>
                              </v-col>
                              <v-col cols="12" md="4">
                                <primary-card
                                  :class-name="'mb-3'"
                                  :loading="summary.totalReceiveLate === null"
                                >
                                  <template #title>
                                    ありがとう
                                  </template>
                                  <template #content>
                                    <base-star />
                                    <template v-if="summary.totalClientEvaluation === null">
                                      <app-progress-circular />
                                    </template>
                                    <template v-else>
                                      <span class="font-weight-bold text-h4 ml-1">
                                        {{ Number(summary.totalClientEvaluation).toFixed(1) }}
                                      </span>
                                    </template>
                                  </template>
                                </primary-card>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>

                        <order-list
                          :items="orderList"
                          :loading="dataTableLoading.order"
                          @on-click-row="onClickOrderListRow"
                          @on-loading="onLoading({ order: $event })"
                          @on-see-more="onSeeMoreOrderList"
                        />
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import AppMethodsMixin from '@/mixins/AppMethodsMixin';
import AppStyleMixin from '@/mixins/AppStyleMixin';
import BaseStar from '@/components/atoms/BaseStar';
import OrderList from '@/components/organisms/OrderList';
import PrimaryCard from '@/components/organisms/PrimaryCard';
import ReceiveList from '@/components/organisms/ReceiveList';

const initResults = () => {
  return {
    results: [],
    previous: null,
    next: null
  };
};

const initSummary = () => {
  return {
    totalApplyCount: null,
    totalChallengerEvaluation: null,
    totalClientEvaluation: null,
    totalOrderCount: null,
    totalOrderLate: null,
    totalReceiveCount: null,
    totalReceiveLate: null,
    totalRegistrationCount: null,
    totalReward: null
  };
};

const initData = () => {
  return {
    dataTableLoading: {
      receive: false,
      order: false
    },
    receiveListHeader: [
      {
        align: 'start',
        text: 'タイトル',
        value: 'quest.title',
        sortable: false
      },
      {
        align: 'start',
        text: 'ステータス',
        value: 'status',
        sortable: false
      },
      {
        align: 'start',
        text: '発注者からの"ありがとう"',
        value: 'evaluation',
        sortable: false
      }
    ],
    receiveList: Object.assign({}, initResults()),
    orderListHeader: [
      {
        align: 'start',
        text: 'タイトル',
        value: 'title',
        sortable: false
      },
      {
        align: 'start',
        text: 'ステータス',
        value: 'status',
        sortable: false
      },
      {
        align: 'start',
        text: '受注者からの"ありがとう"',
        value: 'evaluation',
        sortable: false
      }
    ],
    orderList: Object.assign({}, initResults()),
    summary: Object.assign({}, initSummary()),
    userprofile: {},
    tab: null,
    items: [
      { tab: '受注実績', name: 'receive' },
      { tab: '発注実績', name: 'order' }
    ],
    showPR: 0,
    expandSelfIntroduction: true
  };
};

export default {
  name: 'PublicProfileModal',
  components: {
    BaseStar,
    OrderList,
    PrimaryCard,
    ReceiveList
  },
  mixins: [AppMethodsMixin, AppStyleMixin],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    userInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return initData();
  },
  computed: {
    _show: {
      get() {
        return this.show;
      },
      set() {
        this.onCancel();
      }
    },
    getUserInfoProp() {
      return this.userInfo ?? { nickname: '', profileImg: null, department: { name: '' } };
    }
  },
  methods: {
    /** キャンセルボタン押下時の処理 */
    onCancel(e) {
      this.tab = 0;
      this.showPR = 0;
      this.receiveList = Object.assign({}, initResults());
      this.orderList = Object.assign({}, initResults());
      this.summary = Object.assign({}, initSummary());
      this.$emit('cancel', e);
    },
    /** 応募中クエストをさらに表示 */
    async onSeeMoreReceiveList() {
      const queryString = this.receiveList.next.split('?')[1];
      const responseData = await this.getReceiveList(`?${queryString}`, true);

      // 現在表示している応募中クエストの複製リスト
      const sources = this.receiveList.results.slice();

      // 取得したクエストを追加
      // TODO: 同一のクエストは除外
      responseData.results.forEach(result => { sources.push(result); });

      // 現在表示している応募中クエストを上書き
      this.$set(this.receiveList, 'results', sources);
      this.$set(this.receiveList, 'previous', responseData.previous);
      this.$set(this.receiveList, 'next', responseData.next);
    },
    /** 受注クエスト取得 */
    async getReceiveList(queryString, isAwait=false) {
      if (!queryString) {
        queryString = `?token=${this.userInfo.token}`;
      }

      // 非同期実行
      if (!isAwait) {
        return this.$store.dispatch('receiveQuests/list', queryString);
      }

      // 同期実行
      this.dataTableLoading.receive = true;
      try {
        return await this.$store.dispatch('receiveQuests/list', queryString);
      } finally {
        this.dataTableLoading.receive = false;
      }
    },
    /** 発注クエストをさらに表示 */
    async onSeeMoreOrderList() {
      const queryString = this.orderList.next.split('?')[1];
      const responseData = await this.getOrderList(`?${queryString}`, true);

      // 現在表示している応募中クエストの複製リスト
      const sources = this.orderList.results.slice();

      // 取得したクエストを追加
      // TODO: 同一のクエストは除外
      responseData.results.forEach(result => { sources.push(result); });

      // 現在表示している応募中クエストを上書き
      this.$set(this.orderList, 'results', sources);
      this.$set(this.orderList, 'previous', responseData.previous);
      this.$set(this.orderList, 'next', responseData.next);
    },
    /** 発注クエスト取得 */
    async getOrderList(queryString, isAwait=false) {
      if (!queryString) {
        queryString = `?token=${this.userInfo.token}&is_opened=1`;
      }

      // 非同期実行
      if (!isAwait) {
        return this.$store.dispatch('orderQuests/list', queryString);
      }

      // 同期実行
      this.dataTableLoading.order = true;
      try {
        return await this.$store.dispatch('orderQuests/list', queryString);
      } finally {
        this.dataTableLoading.order = false;
      }
    },
    /** 受注クエストの行クリックイベント */
    onClickReceiveListRow(item) {
      // モーダルを閉じてからクエスト詳細へ遷移
      this.$emit('cancel');
      this.$router.push({ name: 'QuestDetail', params: { questId: item.quest.uuid } }, () => {});
    },
    onClickOrderListRow(item) {
      // モーダルを閉じてからクエスト詳細へ遷移
      this.$emit('cancel');
      this.$router.push({ name: 'QuestDetail', params: { questId: item.uuid } }, () => {});
    },
    onLoading(loading) {
      this.dataTableLoading = { ...this.dataTableLoading, ...loading };
    }
  },
  watch: {
    show(value) {
      // モーダル非表示時は何もしない
      if (!value) {
        return;
      }
      this.showLoadingOverlay(async () => {
        // data初期化
        Object.assign(this.$data, initData());

        // 受注したクエストを一覧取得
        this.dataTableLoading.receive = true;
        this.getReceiveList().then(
          responseData => {
            this.$set(this, 'receiveList', responseData);
          })
          .finally(() => { 
            this.dataTableLoading.receive = false;
          });

        // 発注したクエストを一覧取得
        this.dataTableLoading.order = true;
        this.getOrderList().then(
          responseData => {
            this.$set(this, 'orderList', responseData);
          })
          .finally(() => { 
            this.dataTableLoading.order = false;
          });

        // 実績や評価のサマリーを取得
        this.$store.dispatch('users/summary', `?token=${this.userInfo.token}`).then(
          responseData => {
            this.summary = responseData;
          }
        );

        // プロフィール情報を取得
        this.$store.dispatch('userprofiles/get', this.userInfo.token).then(
          responseData => {
            this.userprofile = responseData;
          }
        );
      });
    },
    $route(to, from) {
      if (to.name === from.name) {
        this.$emit('on-initialize');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-tab {
  color: rgba(0, 0, 0, 0.38) !important;
  font-size: 18px;
  background: rgba(0, 0, 0, 0.1) !important;
  &:first-child {
    border-radius: 24px 0 0 0 !important;
  }
  &:last-child {
    border-radius: 0 24px 0 0 !important;
  }

  &::before {
    opacity: 0 !important;
  }
}

.v-tab--active {
  font-weight: bold;
  color: #FA9F00 !important;
  background: #f7faff !important;

  &::before {
    opacity: 0 !important;
  }
}
</style>
